import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link, navigate } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import PropTypes from "prop-types";

import Button from "../custom-widgets/button";
import Icon from "../custom-widgets/icon";

import styles from "./personalization-cards.module.scss";

/*
 * 3 Small cards image widths at all breakpoints:
 * layout:     | ----------------- 1 col ----------------- | --- 2 col ---|------------------- 3 col -------------------- |
 * Container:  | --- xxs --- | ---- xs ---- | ---- sm ---- | ---- md ---- | ---- lg ----- | ---- xl ----- | ---- xxl ----->
 * Breakpoint: 0            375            600            768            992             1200            1920             ~
 * Img width:  |   290-344   |   345-370    |      546     |     330      |      290      |      350      |      350      |
 *
 * NOTE: passed imaVariable should use "width: 550"
 */

/**
 * @typedef {Object} Cards
 * @property {string} id - Required string id of the card.
 * @property {string} cardClass - optional string for the classes of the div container.
 * @property {string} url - optional url in case we want to use an INTERNAL LINK and give a stretched-link to the card.
 * @property {string} anchorUrl - optional url in case we want to use an EXTERNAL LINK and give a stretched-link to the card.
 * @property {string} titleClass - optional classes for the h3 green title.
 * @property {string} title - optional h3 green text title of the card.
 * @property {string} textClass - optional `<p>` classes.
 * @property {string} text - optional `<p>` text below the title of the card.
 * @property {string} showTextArrow - optional boolean value to show or hide the arrow at the end of the text card.
 * @property {Object} button - optional object for <Button /> at the bottom of the card.
 * @property {Object} image - optional object with the data for the card image.
 * @property {Object} isInBlog - optional boolean value if true generates the ID for the card link `${id}-link` and if false we will use `${id}-url-link`.
 * @property {GatsbyImageData} image.imgVariable - required for image - Gatsby image data.
 * @property {string} image.altText - required for image - string image alt text.
 */

/**
 * This is the component to render the stretched link cards in a row.
 *
 * NOTE: please do NOT change the margin-bottom classes on these cards, it should be "mb-3 mb-lg-4".
 * Do NOT override the spacing using padding or margin classes on the `sectionClass`.
 * If you need to override the section padding because the cards are connected
 * to other sections that need to look like one section, pass the sectionClass
 * "section-padding-top", "section-padding-none", "section-padding-bottom".
 * 
 * @param {Object} props - The object containing props.
 * @param {string} props.sectionId - required string.
 * @param {string} props.sectionClass - optional div whole section container classes like "bg-light".
 * @param {string} props.containerClass - optional div container classes like "bg-light".
 * @param {string} props.rowColsClass - optional `<ul>` element container classes like "row-cols-1 row-cols-md-2 row-cols-lg-3".
 * @param {string} props.titleClass - optional classes for h2 section title above the cards.
 * @param {string} props.title - optional h2 section title above the cards.
 * @param {Object} props.button - optional object for <Button /> at the bottom of the card.
 * @param {string} props.textAlignClass - optional classes for the div container of the link below the cards outside the row.
 * @param {string} props.linkClass - optional classes for the link below the cards outside the row.
 * @param {string} props.link - optional internal link path to go for the link below the cards outside the row.
 * @param {string} props.linkText - optional text to show for the link below the cards outside the row.
 * @param {string} props.linkId - optional id for the link below the cards outside the row.
 * @param {Cards[]} props.cards - required Array of objects.
 *   - `id`: required string id of the card.
 *   - `cardClass`: optional string for the classes of the div container.
 *   - `url`: optional url in case we want to use an INTERNAL LINK and give a stretched-link to the card.
 *   - `anchorUrl`: optional url in case we want to use an EXTERNAL LINK and give a stretched-link to the card.
 *   - `titleClass`: optional classes for the h3 green title.
 *   - `title`: optional h3 green text title of the card.
 *   - `textClass`: optional `<p>` classes.
 *   - `text`: optional `<p>` text below the title of the card.
 *   - `showTextArrow`: optional boolean value to show or hide the arrow at the end of the text card.
 *   - `button`: optional object for <Button /> at the bottom of the card.
 *   - `isInBlog`: optional boolean value if true generates the ID for the card link `${id}-link` and if false we will use `${id}-url-link`.
 *   - `image`: optional object with the data for the card image.
 *   - `image.imgVariable`: required for image - Gatsby image data.
 *   - `image.altText`: required for image - string image alt text.
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const StretchedLinkCards = ({
  sectionClass = "bg-light",
  sectionId = "link-cards-section",
  containerClass = "",
  rowColsClass = "row-cols-1 row-cols-md-2 row-cols-lg-3",
  titleClass = "",
  title = "",
  button = {},
  textAlignClass = "text-sm-center",
  linkClass = "font-weight-bold",
  link = "",
  linkText = "",
  linkId = "",
  useMobileView = false,
  cards = [
    {
      id: "link-card-1",
      cardClass: "",
      url: "",
      anchorUrl: "",
      image: {
        imgSource: null,
        imgVariable: null,
        altText: ""
      },
      titleClass: "",
      title: "",
      textClass: "",
      text: "",
      showTextArrow: true,
      button: {},
      isInBlog: false
    }
  ]
}) => {
  // const growClass = hasGrowEffect ? "grow" : "";

  const desktopClass = useMobileView === true ? "d-none d-md-flex" : "";

  const isLastCard = (idx) => {
    return idx === cards.length - 1;
  };

  const onCardEnter = ({ key }, { url }) => {
    if (key === "Enter" && url) {
      navigate(url);
    }
  };

  const makeCardLinkId = (id, isInBlog) => {
    if (!id) return "";
    if (!isInBlog) return `${id}-url-link`;
    return `${id}-link`;
  };

  // Please use this component when you have only one URL and want to be a stretched link to make an entire card clickable.
  // Otherwise use "no-link-cards.js"

  const DEFAULT_IMAGE = {
    altText: "WaFd Bank logo.",
    imgSrc: "/images/default-source/card-images/thumbnail-walt-camera-081624.jpg"
  };

  return (
    <div id={sectionId} className={`section-padding-adjusted ${sectionClass}`}>
      <div className={`container ${containerClass}`}>
        {title && <h2 className={titleClass}>{title}</h2>}
        <ul className={`row mb-0 pl-0 list-unstyled ${desktopClass} ${rowColsClass}`}>
          {cards.map((card, idx) => (
            <li key={idx} className={`col mb-3 mb-lg-4 ${card.cardClass}`}>
              <div
                id={card.id}
                tabIndex={0}
                onKeyDown={(e) => onCardEnter(e, card)}
                className={`card h-100 position-relative overflow-hidden border-radius-12 border-0 ${styles.stretchedLinkCard}`}
              >
                {!card?.image?.imgSrc && !card?.image?.imgVariable && (
                  <img
                    className={`card-image-top border-radius-12 border-radius-bottom-0`}
                    src={DEFAULT_IMAGE.imgSrc}
                    alt={DEFAULT_IMAGE.altText}
                  />
                )}
                {card?.image?.imgSrc && (
                  <img
                    className={`card-image-top border-radius-12 border-radius-bottom-0`}
                    src={card.image.imgSrc}
                    alt={card.image.altText}
                  />
                )}
                {card?.image?.imgVariable && (
                  <GatsbyImage
                    className={`card-image-top border-radius-12 border-radius-bottom-0`}
                    image={card.image.imgVariable}
                    alt={card.image.altText || ""}
                  />
                )}
                <div className="card-body d-flex flex-column align-items-start">
                  {/* Make the title text green if a card has description */}
                  {/* Add the arrow icon and hover effects to the title when a card doesn't have a description */}
                  {card.title && (
                    <h3
                      className={`card-title ${card.titleClass} ${
                        card.text ? "text-success" : `${styles.cardDescription}`
                      }`}
                    >
                      <span dangerouslySetInnerHTML={{ __html: card.title }} />
                      {!card.text && <Icon name="arrow-right" class="ml-1 text-primary" />}
                    </h3>
                  )}
                  {card.text && (
                    <p className={`card-text ${styles.cardDescription} ${card.textClass}`}>
                      <span dangerouslySetInnerHTML={{ __html: card.text }} />
                      {card.arrowText && <span className="text-primary text-uppercase ml-1">{card.arrowText}</span>}
                      {card.showTextArrow && <Icon name="arrow-right" class="ml-1 text-primary" />}
                    </p>
                  )}
                  {card.tertiaryText && <p className="mb-0 text-sm">{card.tertiaryText}</p>}
                  {card.url && (
                    <Link
                      id={makeCardLinkId(card.id, card.isInBlog)}
                      aria-hidden="true"
                      tabIndex="-1"
                      to={card.url}
                      className="stretched-link"
                    />
                  )}
                  {card.externalUrl && (
                    <a
                      id={makeCardLinkId(card.id, card.isInBlog)}
                      aria-hidden="true"
                      tabIndex="-1"
                      href={card.externalUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="stretched-link"
                    >
                      <span className="sr-only">{card.title}</span>
                    </a>
                  )}
                  {card.anchorUrl && (
                    <AnchorLink to={card.anchorUrl} className="stretched-link">
                      <span className="sr-only" dangerouslySetInnerHTML={{ __html: card.text }} />
                    </AnchorLink>
                  )}
                  {card.button && card.button.text && <Button {...card.button} />}
                </div>
              </div>
            </li>
          ))}
        </ul>

        {useMobileView && (
          <div className="row d-flex d-md-none">
            {cards.map((card, idx) => {
              return (
                <div
                  className={`col-12 pb-2 mb-3 position-relative grow${!isLastCard(idx) ? " border-bottom" : ""}`}
                  id={card.id}
                  key={idx}
                >
                  <div className="row align-items-center">
                    <div className="col-3">
                      {card?.image?.imgVariable && (
                        <GatsbyImage
                          image={card.image.imgVariable}
                          alt={card.image.altText}
                          className="p-5 border-radius-12 border-radius-bottom-0"
                        />
                      )}
                    </div>
                    <div className="col-9 pl-5 pl-sm-3">
                      <p className={`card-text mb-0 ${styles.cardDescription} ${card.textClass}`}>{card.text}</p>
                      <Icon name="arrow-right" class="ml-1 text-primary" />
                    </div>
                  </div>
                  <Link to={card.url} className="stretched-link">
                    <span className="sr-only">{card.title}</span>
                  </Link>
                  {card.anchorUrl && (
                    <AnchorLink to={card.anchorUrl} className="stretched-link">
                      <span className="sr-only">{card.title}</span>
                    </AnchorLink>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {button && button.text && <Button {...button} />}
        {link && linkText && (
          <div className={`pb-3 ${textAlignClass}`}>
            <Link id={linkId} to={link} className={linkClass}>
              {linkText}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
export default StretchedLinkCards;

StretchedLinkCards.propTypes = {
  sectionId: PropTypes.string.isRequired,
  sectionClass: PropTypes.string,
  containerClass: PropTypes.string,
  rowColsClass: PropTypes.string,
  titleClass: PropTypes.string,
  title: PropTypes.string,
  useMobileView: PropTypes.bool,
  button: PropTypes.object,
  textAlignClass: PropTypes.string,
  linkClass: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  linkId: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      cardClass: PropTypes.string,
      url: PropTypes.string,
      anchorUrl: PropTypes.string,
      image: PropTypes.shape({
        imgVariable: PropTypes.object.isRequired,
        altText: PropTypes.string.isRequired
      }),
      titleClass: PropTypes.string,
      title: PropTypes.string,
      textClass: PropTypes.string,
      text: PropTypes.string,
      showTextArrow: PropTypes.bool,
      button: PropTypes.object,
      isInBlog: PropTypes.bool
    })
  ).isRequired
};

