import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../components/contact-info/contact-info";
import SEO from "../../components/seo/seo";
import StretchedLinkCards from "../../components/card/stretched-link-cards";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import MktoForm from "../../components/mkto-form/mkto-form";
import TwoColumnSectionWithImage from "../../components/custom-widgets/two-column-section-with-image";
import BestBanksDefault from "../../components/best-banks/best-banks-default";

//helper functions
import showMktoForm from "../../helpers/showMktoForm";

const CommercialLending = () => {
  const title = "Commercial Lending Services";
  const description =
    "Learn about Commercial Lending Services at WaFd Bank. We can help you with a commercial line of credit, equipment financing, acquisition financing, and more.";
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/commercial-banking/commercial-lending/hero-commercial-lending-01042023-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/commercial-banking/commercial-lending/hero-commercial-lending-01042023-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/commercial-banking/commercial-lending/hero-commercial-lending-01042023-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/commercial-banking/commercial-lending/hero-commercial-lending-01042023-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/commercial-banking/commercial-lending/hero-commercial-lending-01042023-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/commercial-banking/commercial-lending/hero-commercial-lending-01042023-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/commercial-banking/commercial-lending/hero-commercial-lending-01042023-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
      card01: file(relativePath: { eq: "cards/thumbnail-line-of-credit-02072023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card02: file(relativePath: { eq: "cards/thumbnail-equipment-financing-02072023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card03: file(relativePath: { eq: "cards/thumbnail-acquisition-02072023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card04: file(relativePath: { eq: "cards/thumbnail-oo-real-estate-02072023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card05: file(relativePath: { eq: "cards/thumbnail-commercial-term-loans-02072023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card06: file(relativePath: { eq: "cards/thumbnail-professional-service-firms-02072023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);
  const SEOData = {
    title: "Commercial Lending Services",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/commercial-lending"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-commercial-lending-01042023-250.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "commercial-lending-hero",
    ...getHeroImgVariables(imgData),
    altText: "Two businessmen shaking hands at work",
    chevronBgClass: "bg-info",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Customized Services to Help You Meet Your Goals"
          }
        },
        {
          id: 2,
          button: {
            class: "btn-white",
            id: "commercial-lending-hero-cta",
            onClick: () => showMktoForm(1067),
            type: "button",
            text: "Contact Us"
          }
        }
      ]
    }
  };
  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      active: true,
      title: "Commercial Lending"
    }
  ];
  const cardsData = {
    sectionClass: "",
    titleClass: "",
    title: "",
    button: "",
    hasGrowEffect: true,
    cards: [
      {
        id: "commercial-lending-card-01",
        url: "/commercial-banking/commercial-line-of-credit",
        title: "Commercial Line of Credit",
        text: "With customized commercial lines of credit, we provide the flexibility your business needs.",
        showTextArrow: true,
        image: {
          imgVariable: imgData.card01.childImageSharp.gatsbyImageData,
          altText: "Banker talking with client in office."
        }
      },
      {
        id: "commercial-lending-card-02",
        url: "/commercial-banking/business-equipment-financing",
        title: "Equipment Finance",
        text: "We provide the resources and expertise you need for leasing equipment and financing for equipment replacement.",
        showTextArrow: true,
        image: {
          imgVariable: imgData.card02.childImageSharp.gatsbyImageData,
          altText: "Warehouse worker driving a forklift in a storage room."
        }
      },
      {
        id: "commercial-lending-card-03",
        url: "/commercial-banking/acquisition-financing",
        title: "Acquisition Financing",
        text: "Customized acquisition financing is based on your business needs, goals and financing characteristics.",
        showTextArrow: true,
        image: {
          imgVariable: imgData.card03.childImageSharp.gatsbyImageData,
          altText: "Team of businesspeople sitting together at a meeting in the office."
        }
      },
      {
        id: "commercial-lending-card-04",
        url: "/commercial-banking/owner-occupied-real-estate-financing",
        title: "Owner Occupied Real Estate",
        text: "We can help you build or remodel the perfect property for your business.",
        showTextArrow: true,
        image: {
          imgVariable: imgData.card04.childImageSharp.gatsbyImageData,
          altText: "Construction worker and architect talking in building under construction."
        }
      },
      {
        id: "commercial-lending-card-05",
        url: "/commercial-banking/commercial-term-loans",
        title: "Commercial Term Loans",
        text: "With customized payments for your business' cash flow, a term loan is a practical solution for a variety of business goals.",
        showTextArrow: true,
        image: {
          imgVariable: imgData.card05.childImageSharp.gatsbyImageData,
          altText: "Two people in hardhats talking by shipping containers."
        }
      },
      {
        id: "commercial-lending-card-06",
        url: "/commercial-banking/specialized-industries/professional-service-firms",
        title: "Professional Practice and Services",
        text: "With years of experience banking professional practice and service professionals, we speak your language. Count on us for tailored financing solutions, whether you’re acquiring a practice, buying into one, or expanding.",
        showTextArrow: true,
        image: {
          imgVariable: imgData.card06.childImageSharp.gatsbyImageData,
          altText: "Two business people walking and talking in an office building."
        }
      }
    ]
  };

  const TreasureManagementContent = () => {
    return (
      <>
        <h2 className="text-success font-weight-semi-bold">Treasury Management Solutions</h2>
        <p>
          Looking for other ways to manage cash resources? From security and fraud prevention to simplified payables and
          receivables, WaFd Bank's got you covered.
        </p>
        <Link
          id={"benefits-cta-contact-us"}
          class={"btn btn-link no-min-width w-100 w-sm-auto"}
          to="/commercial-banking/treasury-management-services"
        >
          Treasury Management
        </Link>
      </>
    );
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <MktoForm />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Commercial Lending</h1>
        <h3 className="mb-0">
          When you partner with WaFd Bank, you gain access to our Commercial Bankers' expertise, treasury management
          services and customized financing solutions tailored for your business goals.
        </h3>
      </section>
      <StretchedLinkCards {...cardsData} />
      <TwoColumnSectionWithImage isGray={true} contentOnLeft={false}>
        {{
          image: (
            <StaticImage
              src="../../images/thumbnail-treasury-management-022824.jpg"
              alt="Business man smiling at his desk while talking on a phone. "
              placeholder="blurred"
              quality="100"
              className="border-radius-12"
            />
          ),
          content: <TreasureManagementContent />
        }}
      </TwoColumnSectionWithImage>
      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default CommercialLending;
